import { generateCyrillic } from "../../utils/language";

const en = {
  aboutUs: "About Us",
  aboutUsText:
    "At 3nity, we specialize in building web and mobile solutions to make your ideas come to life and your business see the light of digital age",
  aboutUsText2:
    "Our mission is to make use of cutting-edge technologies to improve your online presence and make your company's internal workflow easier to manage",
};

const lat = {
  aboutUs: "O nama",
  aboutUsText:
    "U 3nity, mi se specijalizujemo u izgradnji web i mobilnih rešenja koja će oživeti vaše ideje i pomoći vašem biznisu da vidi svetlo digitalnog doba",
  aboutUsText2:
    "Naša misija je da iskoristimo najmodernije tehnologije da bi poboljšali vaše online prisustvo i time olakšamo organizaciju vaših poslova",
};

const cir = generateCyrillic(lat);

const language = {
  en: en,
  lat: lat,
  cir: cir,
};

export default language;
