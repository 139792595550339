import React, { useState, useContext } from "react";
import { getLanguage } from "../../utils/language";
import LanguageContext from "../../contexts/LanguageContext";
import LanguageIcon from "@mui/icons-material/Language";
import prevod from "../LanguageSwitcher/prevod";
import { IconButton, Box, Tooltip, Menu, MenuItem } from "@mui/material";
import "../../styles/navbar.css";

enum textColor {
  LIGHT = "#F2F2F2",
  DARK = "#262626",
}

interface LanguageSwitcherProps {
  color?: boolean;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ color }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const languageData = getLanguage(prevod, language);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [currentColor, setCurrentColor] = React.useState(textColor.LIGHT);
  const newColorRef = React.useRef(textColor.LIGHT);

  const documentEl = color ? ".Burger" : ".Navbar";

  React.useEffect(() => {
    window.addEventListener("scroll", (event) => {
      event.preventDefault();

      const navbarEl = document.querySelector(documentEl) as HTMLElement;
      const pwSection = document.querySelector("#previousWork") as HTMLElement;
      const pwSection2 = document.querySelector("#textSection") as HTMLElement;

      if (navbarEl) {
        const navbarRect = navbarEl.getBoundingClientRect();
        const pwSectionRect = pwSection.getBoundingClientRect();
        const pwSectionRect2 = pwSection2.getBoundingClientRect();

        if (
          ((navbarRect.bottom + navbarRect.top)/2 < pwSectionRect.bottom &&
            (navbarRect.bottom + navbarRect.top)/2 > pwSectionRect.top) ||
          ((navbarRect.bottom + navbarRect.top)/2 < pwSectionRect2.bottom &&
            (navbarRect.bottom + navbarRect.top)/2 > pwSectionRect2.top)
        ) {
          newColorRef.current = textColor.DARK;
        } else {
          newColorRef.current = textColor.LIGHT;
        }

        if (newColorRef.current !== currentColor)
          setCurrentColor(newColorRef.current);
      }
    });
  }, [currentColor, language, documentEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lang: string) => {
    if (typeof lang === "string") {
      setLanguage(lang);
    } else {
      setLanguage(language);
    }
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={languageData.changeLang}>
          <IconButton
            onClick={handleClick}
            disableRipple
            size="small"
            sx={{ 
              ml: 2,
              borderRadius: 0
            }}
            aria-controls={open ? "account-menu" : undefined}

            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <LanguageIcon
              className="language-icon"
              sx={{ color: currentColor }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleClose("en")}>{languageData.en}</MenuItem>
        <MenuItem onClick={() => handleClose("lat")}>
          {languageData.lat}
        </MenuItem>
        <MenuItem onClick={() => handleClose("cir")}>
          {languageData.cir}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default LanguageSwitcher;
