import { generateCyrillic } from "../../utils/language";

const en = {
  home: "Digital Solutions",
  slogan: "Crafted To Meet Your",
  slogan2: "Needs",
  podSlogan: "Building the digital world, one pixel at a time",
};

const lat = {
  home: "Digitalna Rešenja",
  slogan: "Napravljena Da Zadovolje Vaše",
  slogan2: "Potrebe",
  podSlogan: "Gradimo digitalni svet, piksel po piksel",
};

const cir = generateCyrillic(lat);

const language = {
  en: en,
  lat: lat,
  cir: cir,
};

export default language;
