import * as React from "react";
import "../../styles/navbar.css";
import { useMediaQuery } from "@material-ui/core";
import TopMenu from "./TopMenu";
import PersistentBurgerMenu from "./PersistentBurgerMenu";
import theme from "../../constants/theme"

// const theme = createTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 960,
//       lg: 1024,
//       xl: 1920,
//     },
//   },
// });

export default function Navbar() {
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  return <>{matches ? <PersistentBurgerMenu /> : <TopMenu />}</>;
}
