import { Routes, Route } from "react-router-dom";
// import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/home" />} /> */}
      {/* <Route path="/home" element={<HomePage />} /> */}
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
};

export default AppRoutes;
