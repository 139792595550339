import prevod from "./prevod";
import useLanguage from "../../hooks/utils/useLanguage";
import "../../styles/previousWork.css";
import "../../styles/navbar.css";
import { IconButton, Grid, Typography, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextUnderline from "../TextUnderline";
import React from "react";
import { useState, useEffect, useRef } from "react";

const Preview = ({
  display,
  seeMore,
  text,
}: {
  display: string;
  seeMore: string;
  text: string;
}) => {
  return (
    <div className={display}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="item-container-overlay"
      >
        <Grid item>
          <Typography className={`${display}-text`}>{text}</Typography>
        </Grid>
        <Grid item>
          <IconButton className={`${display}-button`}>
            <Tooltip title={seeMore}>
              <VisibilityIcon
                sx={{ color: "#41d2bf" }}
                className="displayed-icon"
              />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const PreviousWork = () => {
  const lang = useLanguage(prevod);
  const [isHovered1, setIsHovered1] = React.useState("notdisplayed");
  const [isHovered2, setIsHovered2] = React.useState("notdisplayed");
  const [isHovered3, setIsHovered3] = React.useState("notdisplayed");
  const [isHovered4, setIsHovered4] = React.useState("notdisplayed");

  const containerRef1 = useRef<HTMLDivElement>(null);
  const containerRef2 = useRef<HTMLDivElement>(null);
  const containerRef3 = useRef<HTMLDivElement>(null);
  const containerRef4 = useRef<HTMLDivElement>(null);

  const [hasScrolledContainer1, setHasScrolledContainer1] = useState(false);
  const [hasScrolledContainer2, setHasScrolledContainer2] = useState(false);
  const [hasScrolledContainer3, setHasScrolledContainer3] = useState(false);
  const [hasScrolledContainer4, setHasScrolledContainer4] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolledContainer1 && containerRef1.current) {
        const { top } = containerRef1.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer1(true);
          containerRef1.current.classList.add("animate1");
        }
      }

      if (!hasScrolledContainer2 && containerRef2.current) {
        const { top } = containerRef2.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer2(true);
          containerRef2.current.classList.add("animate2");
        }
      }

      if (!hasScrolledContainer3 && containerRef3.current) {
        const { top } = containerRef3.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer3(true);
          containerRef3.current.classList.add("animate3");
        }
      }

      if (!hasScrolledContainer4 && containerRef4.current) {
        const { top } = containerRef4.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer4(true);
          containerRef4.current.classList.add("animate4");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    hasScrolledContainer1,
    hasScrolledContainer2,
    hasScrolledContainer3,
    hasScrolledContainer4,
  ]);

  return (
    <div className="background-image-previous" id="previousWork">
      <div
        className="text-container-previous"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextUnderline text={lang.title} color="#262626" />
        <div className="grid-container">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{ cursor: "pointer" }}
              >
                <Grid
                  ref={containerRef1}
                  item
                  className="item-container-previous-levo"
                  position="relative"
                  onMouseEnter={() => setIsHovered1("displayed")}
                  onMouseLeave={() => setIsHovered1("notdisplayed")}
                >
                  <div
                    style={{
                      backdropFilter:
                        isHovered1 === "displayed" ? "blur(2px)" : "none",
                      transition: "filter 500ms ease-in-out",
                    }}
                    className="background-1"
                  >
                    <a
                      href="https://jewelryshopdoro.com"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={`${isHovered1}-layer`}>
                        <Preview
                          display={isHovered1}
                          seeMore={lang.seeMore}
                          text={lang.projekat1}
                        />
                      </div>
                    </a>
                  </div>
                </Grid>
                <Grid
                  ref={containerRef2}
                  item
                  className="item-container-previous-levo"
                  position="relative"
                  onMouseEnter={() => setIsHovered2("displayed")}
                  onMouseLeave={() => setIsHovered2("notdisplayed")}
                >
                  <div
                    style={{
                      backdropFilter:
                        isHovered2 === "displayed" ? "blur(2px)" : "none",
                      transition: "filter 500ms ease-in-out",
                    }}
                    className="background-2"
                  >
                    <a
                      href="https://gdpromet.com/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={`${isHovered2}-layer`}>
                        <Preview
                          display={isHovered2}
                          seeMore={lang.seeMore}
                          text={lang.projekat2}
                        />
                      </div>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Grid
                  ref={containerRef3}
                  item
                  position="relative"
                  className="item-container-previous-desno"
                  onMouseEnter={() => setIsHovered3("displayed")}
                  onMouseLeave={() => setIsHovered3("notdisplayed")}
                >
                  <div
                    style={{
                      backdropFilter:
                        isHovered3 === "displayed" ? "blur(2px)" : "none",
                      transition: "filter 500ms ease-in-out",
                    }}
                    className="background-3"
                  >
                    <div className={`${isHovered3}-layer`}>
                      <Preview
                        display={isHovered3}
                        seeMore={lang.seeMore}
                        text={lang.projekat3}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  ref={containerRef4}
                  item
                  position="relative"
                  className="item-container-previous-desno"
                  onMouseEnter={() => setIsHovered4("displayed")}
                  onMouseLeave={() => setIsHovered4("notdisplayed")}
                >
                  <div
                    style={{
                      backdropFilter:
                        isHovered4 === "displayed" ? "blur(2px)" : "none",
                      transition: "filter 500ms ease-in-out",
                      objectFit: "fill",
                    }}
                    className="background-4"
                  >
                    <div className={`${isHovered4}-layer`}>
                      <Preview
                        display={isHovered4}
                        seeMore={lang.seeMore}
                        text={lang.projekat4}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default PreviousWork;
