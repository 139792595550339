import { generateCyrillic } from "../../utils/language";

const en = {
  title: "Services",
  building: "Building a website",
  buildingText:
    "We can build a landing page or full website to represent your company or product. Our team can either redesign your website or build new one from scratch. We can also work with your own design or create a custom design tailored to your brand",
  mobile: "Building a web or mobile application",
  mobileText:
    "Our developer team is highly skilled and able to create complex web and mobile applications featuring a wide range of capabilities, such as order processing, payment integration, user accounts, analytics, and more",
  workflow: "Digitalizing your company’s workflow",
  workflowText:
    "Whether you're looking to improve productivity of your employees or streamline approval processes, we have the expertise to develop a custom solution that meets your specific needs",
};

const lat = {
  title: "Usluge",
  building: "Građenje web stranice",
  buildingText:
    "Možemo da izgradimo naslovnu stranu ili celu web stranicu koja će reprezentovati vašu kompaniju ili vaš proizvod. Naš tim može da redizajnira vašu postojeću stranicu ili da izgradi novu ispočetka. Možemo raditi sa dizajnom koji nam vi pružite ili možemo napraviti unikatan dizajn prilagođen samo za vaš brend",
  mobile: "Građenje internet ili mobilne aplikacije",
  mobileText:
    "Naš tim programera je visoko obučen i sposoban da kreira složene internet i mobilne aplikacije sa širokim spektrom mogućnosti, kao što su obrada porudžbina, integracija plaćanja, korisnički nalozi, analitika i još mnogo toga",
  workflow: "Digitalizacija toka posla vaše kompanije",
  workflowText:
    "Bilo da želite da poboljšate produktivnost svojih zaposlenih  ili da pojednostavite procese odobravanja, imamo stručnost da razvijemo prilagođeno rešenje koje zadovoljava vaše specifične potrebe",
};

const cir = generateCyrillic(lat);

const lang = {
  en: en,
  lat: lat,
  cir: cir,
};

export default lang;
