import prevod from "./prevod";
import useLanguage from "../../hooks/utils/useLanguage";
import "../../styles/textSection.css";
import { Typography, Grid } from "@mui/material";
import { useState, useEffect, useRef } from "react";

const TextSection = () => {
  const lang = useLanguage(prevod);
  const [hasScrolled, setHasScrolled] = useState(false);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const titleRef1 = useRef<HTMLDivElement>(null);
  const titleRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !hasScrolled &&
        subtitleRef.current &&
        titleRef1.current &&
        titleRef2.current
      ) {
        const { top } = subtitleRef.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolled(true);
          subtitleRef.current.classList.add("animate");
          titleRef1.current.classList.add("animate");
          titleRef2.current.classList.add("animate");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);
  return (
    <div className="background-image-text" id="textSection">
      <div className="text-container-text">
        <Grid container direction="column" alignContent="center">
          <Grid item>
            <Typography className="title-short-text" ref={titleRef1}>
              {lang.textSection}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="title-short-text" ref={titleRef2}>
              {lang.textSection2}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="subtitle-short-text" ref={subtitleRef}>
              {lang.slogan}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TextSection;
