import prevod from "./prevod";
import useLanguage from "../../hooks/utils/useLanguage";
import TextUnderline from "../TextUnderline";
import "../../styles/services.css";
import { Grid, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";

const ServiceItem = ({
  image,
  title,
  text,
}: {
  image: string;
  title: string;
  text: string;
}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className="service-item"
    >
      <Grid item>
        <img src={image} alt="Building A Website" className="picture-icon" />
      </Grid>
      <Grid item>
        <Typography className="service-title">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography className="service-text">{text}</Typography>
      </Grid>
    </Grid>
  );
};

const Services = () => {
  const lang = useLanguage(prevod);
  const containerRef1 = useRef<HTMLDivElement>(null);
  const containerRef2 = useRef<HTMLDivElement>(null);
  const containerRef3 = useRef<HTMLDivElement>(null);

  const [hasScrolledContainer1, setHasScrolledContainer1] = useState(false);
  const [hasScrolledContainer2, setHasScrolledContainer2] = useState(false);
  const [hasScrolledContainer3, setHasScrolledContainer3] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolledContainer1 && containerRef1.current) {
        const { top } = containerRef1.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer1(true);
          containerRef1.current?.classList.add("animate1");
        }
      }

      if (!hasScrolledContainer2 && containerRef2.current) {
        const { top } = containerRef2.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer2(true);
          containerRef2.current?.classList.add("animate2");
        }
      }

      if (!hasScrolledContainer3 && containerRef3.current) {
        const { top } = containerRef3.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer3(true);
          containerRef3.current?.classList.add("animate3");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledContainer1, hasScrolledContainer2, hasScrolledContainer3]);

  return (
    <div className="background-image-services" id="services">
      <Grid
        container
        className="text-container-services"
        direction="column"
        alignItems="center"
      >
        <Grid item>
          <TextUnderline text={lang.title} color="#FEFEFE" />
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
            columnSpacing={12}
            rowSpacing={5}
          >
            <Grid item className="service-container" ref={containerRef1}>
              <ServiceItem
                image="./buildingWebsite.webp"
                title={lang.building}
                text={lang.buildingText}
              />
            </Grid>
            <Grid item className="service-container" ref={containerRef2}>
              <ServiceItem
                image="./mobile.webp"
                title={lang.mobile}
                text={lang.mobileText}
              />
            </Grid>
            <Grid item className="service-container" ref={containerRef3}>
              <ServiceItem
                image="./workflow.webp"
                title={lang.workflow}
                text={lang.workflowText}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Services;
