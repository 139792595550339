import { generateCyrillic } from "../../utils/language";

const en = {
  footer: "Footer",
  slogan: "Building the digital world, one pixel at a time",
  learnMore: "Want to learn more about us?",
  contact: "Book an appointment, or send us a message if you prefer.",
  email: "3nityoffice@gmail.com",
  copyright: "Copyright 2023 3nity Solutions. All Rights Deserved",
};

const lat = {
  footer: "Footer",
  slogan: "Gradimo digitalni svet, piksel po piksel",
  learnMore: "Želite da saznate više o nama?",
  contact:
    "Zakažite sastanak, ili nam pošaljite poruku ako vam to više odgovara",
  email: "3nityoffice@gmail.com",
  copyright: "Autorska prava 2023 3nity Solutions. Sva prava zadržana",
};

const cir = generateCyrillic(lat);

const language = {
  en: en,
  lat: lat,
  cir: cir,
};

export default language;
