import { generateCyrillic } from "../../utils/language";

const en = {
  textSection: "Bringing your next project to",
  textSection2: "new heights.",
  slogan: "Together, we will make it happen",
};

const lat = {
  textSection: "Podižemo vaš sledeći projekat na",
  textSection2: "novi nivo.",
  slogan: "Zajedno, ostvarićemo sve",
};

const cir = generateCyrillic(lat);

const language = {
  en: en,
  lat: lat,
  cir: cir,
};

export default language;
