import useLanguage from "../../hooks/utils/useLanguage";
import prevod from "./prevod";
import TextUnderline from "../TextUnderline";
import "../../styles/contact.css";
import React from "react";
import { useRef, useState, useEffect } from "react";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { PopupModal } from "react-calendly";

const Contact = () => {
  const lang = useLanguage(prevod);
  const root = document.getElementById("root");
  const rootElement = root ? root : undefined;
  const [open, setOpen] = React.useState(false);
  const containerRef1 = useRef<HTMLDivElement>(null);
  const containerRef2 = useRef<HTMLDivElement>(null);
  const [hasScrolledContainer1, setHasScrolledContainer1] = useState(false);
  const [hasScrolledContainer2, setHasScrolledContainer2] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolledContainer1 && containerRef1.current) {
        const { top } = containerRef1.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer1(true);
          containerRef1.current.classList.add("animate1");
        }
      }

      if (!hasScrolledContainer2 && containerRef2.current) {
        const { top } = containerRef2.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolledContainer2(true);
          containerRef2.current.classList.add("animate2");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolledContainer1, hasScrolledContainer2]);
  return (
    <div className="background-image-contact" id="contact">
      <div
        className="text-container-contact"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextUnderline color="#FEFEFE" text={lang.contact} />
        <div className="grid-container">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
            spacing={3}
          >
            <Grid item ref={containerRef1} className="contact-image">
              <img
                src="./contactUs.gif"
                alt="Contacting"
                className="image-contact"
              />
            </Grid>
            <Grid item ref={containerRef2} className="contact-text">
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={3}
              >
                <Link
                  style={{ textDecoration: "none" }}
                  to="#"
                  onClick={(e) => {
                    window.open(
                      `mailto:3nityoffice@gmail.com?subject=${lang.subject}`,
                      "_blank"
                    );
                    e.preventDefault();
                  }}
                >
                  <Grid item>
                    <Tooltip title={lang.sendMail}>
                      <Grid
                        container
                        direction="row"
                        spacing={3}
                        justifyContent="flex-start"
                      >
                        <Grid item>
                          <MailOutlineIcon className="icon-contact" />
                        </Grid>
                        <Grid item>
                          <Typography className="text-contact">
                            3nityoffice@gmail.com
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Link>
                <Grid item>
                  <Button
                    variant="outlined"
                    className="button-contact"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {lang.appoint}
                  </Button>
                  {rootElement ? (
                    <PopupModal
                      url="https://calendly.com/3nityoffice"
                      onModalClose={() => setOpen(false)}
                      open={open}
                      rootElement={rootElement}
                      pageSettings={{
                        backgroundColor: "#262626",
                        primaryColor: "#41D2BF",
                        textColor: "#FEFEFE",
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Contact;
