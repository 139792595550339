import "../../styles/hero.css";
import { Typography, Grid } from "@mui/material";
import prevod from "./prevod";
import useLanguage from "../../hooks/utils/useLanguage";
import { useEffect } from "react";

const Hero = () => {
  const lang = useLanguage(prevod);

  useEffect(() => {
    const textContainer = document.querySelector(".text-container-home");
    textContainer?.classList.add("animated");
  }, []);

  return (
    <div className="background-image-home">
      <div className="text-container-home animated">
        <Grid container direction="column">
          <Grid item className="text-container-hero">
            <Grid container direction="column">
              <Typography className="text-title-hero">{lang.home}</Typography>
              <Typography className="text-subtitle-hero">
                {lang.slogan}
              </Typography>
              <Typography className="text-subtitle-hero">
                {lang.slogan2}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="subtitle-container-hero">
            <Typography className="text-slogan-hero">
              {lang.podSlogan}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Hero;
