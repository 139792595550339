import { generateCyrillic } from "../../utils/language";

const en = {
  contact: "Contact Us",
  email: "3nityoffice@gmail.com",
  appoint: "Make an appointment",
  subject: "Potential Client",
  sendMail: "Send an E-mail",
};

const lat = {
  contact: "Kontakt",
  email: "3nityoffice@gmail.com",
  appoint: "Zakažite sastanak",
  subject: "Potencijalni Klijent",
  sendMail: "Pošaljite E-mail",
};

const cir = generateCyrillic(lat);

const language = {
  en: en,
  lat: lat,
  cir: cir,
};

export default language;
