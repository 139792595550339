import { generateCyrillic } from "../../utils/language";

const en = {
  en: "English",
  lat: "Serbian(Latin)",
  cir: "Serbian(Cyrillic)",
  changeLang: "Change language",
};

const lat = {
  en: "Engleski",
  lat: "Srpski(Latinica)",
  cir: "Srpski(Ćirilica)",
  changeLang: "Promeni jezik",
};

const cir = generateCyrillic(lat);

const language = {
  en: en,
  lat: lat,
  cir: cir,
};

export default language;
