import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import AppRoutes from "./routes";
import "./styles/loadingScreen.css";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const Container = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (initialLoad) {
      setTimeout(() => {
        setInitialLoad(false);
      }, 2000);
    }
  }, [initialLoad]);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (initialLoad) {
    return (
      <div className="background-image-loading">
        <img src="./3nity-logo-cropped.webp" className="logo" alt="Logo" />
        <Box sx={{ width: "50%", mx: "auto" }}>
          <LinearProgress
            variant="buffer"
            value={progress}
            valueBuffer={buffer}
            sx={{ color: "#41d2bf" }}
          />
        </Box>
      </div>
    );
  }
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default Container;
