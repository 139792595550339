import prevod from "./prevod";
import useLanguage from "../../hooks/utils/useLanguage";
import "../../styles/aboutUs.css";
import { Grid, Typography } from "@mui/material";
import TextUnderline from "../TextUnderline";
import LanguageContext from "../../contexts/LanguageContext";
import React, { useContext, useState, useEffect, useRef } from "react";

const AboutUsPage = () => {
  const lang = useLanguage(prevod);
  const { language } = useContext(LanguageContext);
  const [hasScrolled, setHasScrolled] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled && imgRef.current && textRef.current) {
        const { top } = imgRef.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolled(true);
          imgRef.current?.classList.add("animate");
          textRef.current?.classList.add("animateText");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);
  return (
    <div className="background-image-about" id="aboutUs">
      <Grid
        container
        className="text-container-about"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextUnderline text={lang.aboutUs} color="#FEFEFE" />
        <div className="grid-container">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Grid item>
              <img
                ref={imgRef}
                src="./typing.webp"
                alt="Typing"
                className="img-about"
              />
            </Grid>
            <Grid item className="text-holder" ref={textRef}>
              <Typography className="text-about">
                {language === "cir"
                  ? lang.aboutUsText.replace("3нитy", "3nity")
                  : lang.aboutUsText}
              </Typography>
              <Typography className="text-about">
                {lang.aboutUsText2}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default AboutUsPage;
