import "./App.css";

import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import LanguageContext from "./contexts/LanguageContext";
import { SnackbarProvider } from "notistack";
import Container from "./Container";
import theme from "./constants/theme";

const allowedLanguages = ["en", "lat", "cir"];

function App() {
  const [language, setLanguage] = useState("en");

  const updateLanguage = (lang: string) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const languageValue = { language, setLanguage: updateLanguage };

  const getSavedLanguage = () => {
    const language = localStorage.getItem("language");
    if (language && allowedLanguages.includes(language)) {
      setLanguage(language);
    } else {
      setLanguage("en");
    }
  };

  const onStorageLanguageChange = React.useCallback((event: StorageEvent) => {
    if (event.key === "language") {
      setLanguage(event.newValue || "en");
    }
  }, []);

  useEffect(() => {
    getSavedLanguage();
    window.addEventListener("storage", onStorageLanguageChange);

    return () => {
      window.removeEventListener("storage", onStorageLanguageChange);
    };
  }, [onStorageLanguageChange]);

  return (
    <React.StrictMode>
      <LanguageContext.Provider value={languageValue}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Container />
          </SnackbarProvider>
        </ThemeProvider>
      </LanguageContext.Provider>
    </React.StrictMode>
  );
}

export default App;
