import useLanguage from "../../hooks/utils/useLanguage";
import prevod from "./prevod";
import "../../styles/footer.css";
import { Grid, Typography } from "@mui/material";
const Footer = () => {
  const lang = useLanguage(prevod);

  return (
    <div className="background-image-footer">
      <Grid
        container
        className="footer-container"
        justifyContent="space-between"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Grid item className="footer-logo-container">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <img
                src={"./3nity-logo-footer.webp"}
                alt="Logo"
                className="footer-logo"
              />
            </Grid>
            <Grid item>
              <Typography className="footer-logo-text">
                {lang.slogan}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="footer-border-container">
          <Grid container direction="column" className="footer-learn-container">
            <Grid item>
              <Typography className="footer-learn-text">
                {lang.learnMore}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="footer-learn-appointment">
                {lang.contact}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="footer-learn-mail">
                3nityoffice@gmail.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="footer-copy-container">
          <Typography className="footer-copy-text">{lang.copyright}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
