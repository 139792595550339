import { Typography } from "@mui/material";
import "../../styles/textUnderline.css";
import React, { useState, useEffect, useRef } from "react";

const TextUnderline = ({ text, color }: { text: string; color: string }) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled && textRef.current) {
        const { top } = textRef.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setHasScrolled(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);
  return (
    <div className="text-container">
      <Typography
        className={`text-underline${hasScrolled ? " animate" : ""}`}
        color={color}
        ref={textRef}
      >
        {text}
      </Typography>
    </div>
  );
};

export default TextUnderline;
