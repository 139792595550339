import AboutUs from "../../components/AboutUs";
import Hero from "../../components/Hero";
import PreviousWork from "../../components/PreviousWork";
import Navbar from "../../components/Navbar";
import TextSection from "../../components/TextSection";
import Services from "../../components/Services";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
const HomePage = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Navbar />
      <Hero />
      <TextSection />
      <AboutUs />
      <Services />
      <PreviousWork />
      <Contact />
      <Footer />
    </div>
  );
};

export default HomePage;
