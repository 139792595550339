import { getLanguage } from "../../utils/language";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";

const useLanguage = (prevod: any) => {
  const { language } = useContext(LanguageContext);
  const lang = getLanguage(prevod, language);
  return lang;
};

export default useLanguage;
