const color = {
  primary: "#41D2BF",
  accent: "#262626",
  secondary: "#F2F2F2",
  secondaryDark: "#58595b",
  black: "#000000",
  white: "#FFFFFF",
  red: "#E21D39",
};

export default color;
