import { generateCyrillic } from "../../utils/language";

const en = {
  title: "Previous Work",
  projekat1:
    "Advanced e-commerce platform with a comprehensive CMS system for efficient online store management. Optimized user experience, secure transactions, and easy product navigation.",
  projekat2:
    "Advanced e-commerce platform with a comprehensive CMS system for efficient online store management. Optimized user experience and easy product navigation.",
  projekat3:
    "Responsive Animated Landing Page for Voda Diva - Designed and developed captivating mobile-friendly page with seamless navigation, engaging web animations, and strong branding.",
  projekat4:
    "A gripping mobile-friendly page with smooth navigation, interesting web animations, and robust brand presence was meticulously crafted and established as a responsive animated landing page for the bookkeeping agency Insolvent DOO.",
  seeMore: "See more",
};

const lat = {
  title: "Prethodni projekti",
  projekat1:
    "Napredna e-commerce platforma sa kompletnim CMS sistemom za efikasno upravljanje online prodavnicom. Optimizovanu korisničku iskustvo, bezbednost transakcija i laku navigacija kroz asortiman proizvoda.",
  projekat2:
    "Napredna e-commerce platforma sa kompletnim CMS sistemom za efikasno upravljanje online prodavnicom. Optimizovanu korisničku iskustvo i laku navigacija kroz asortiman proizvoda.",
  projekat3:
    "Responsivna animirana stranica za kompaniju Voda Diva. Dizajnirana i razvijena upečatljiva stranica prilagođena mobilnim uređajima sa glatkim navigacijom, privlačnim web animacijama i snažnim brendiranjem.",
  projekat4:
    "Zavodljiva stranica prilagođena mobilnim uređajima sa glatkom navigacijom, zanimljivim web animacijama i snažnom prisutnošću brenda, pažljivo je osmišljena i uspostavljena za agenciju za vođenje knjiga Insolvent DOO.",
  seeMore: "Vidi još",
};

const cir = generateCyrillic(lat);

const language = {
  en: en,
  lat: lat,
  cir: cir,
};

export default language;
